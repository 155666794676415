.n-wrapper {
  min-height: 5rem;
  padding: 0 2rem;
}
.n-wrapper nav .n-logo {
  width: 250px;
}

.n-wrapper nav .n-links {
  justify-content: space-between;
  transition: 0.5s ease-in-out;
}
.n-wrapper nav .n-links ul,
.n-wrapper nav .n-contact {
  gap: 25px;
}

.n-wrapper nav .n-hamBurger,
.n-ham-search {
  display: none;
}
.n-searchContainer {
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 100px;
  right: 80px;
  transition: 0.5s ease-in-out;
}
.s-wrapper .s-container input {
  border: 1px solid black;
}

@media (max-width: 1024px) {
  .n-wrapper {
    position: relative;
  }
  .n-wrapper nav {
    justify-content: space-between;
  }
  .n-wrapper nav .n-links.active {
    display: block;
  }

  .n-wrapper nav .n-links {
    position: absolute;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 11;
    display: none;
    backdrop-filter: blur(10px);
  }
  .n-wrapper nav .n-links ul {
    background-color: var(--clr-gray);
    height: 100%;
    width: 50%;
    flex-direction: column;
    position: absolute;
    right: 0;
  }
  .n-wrapper nav .n-contact {
    display: none;
  }
  .n-wrapper nav .n-hamBurger {
    display: block flex;
    gap: 25px;
  }
  .n-searchContainer {
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 180px;
    /* z-index: 10; */
  }
}
@media (min-width: 800px) {
  .n-ham-search {
    display: block;
  }
}
@media (max-width: 690px) {
  .n-searchContainer {
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 100px;
    right: 10px;
    z-index: 15;
  }
}
