.single-product {
  flex-direction: column;
  width: fit-content;
  padding: 1rem;
  border: 1px solid rgb(222, 222, 222);
  border-radius: 1rem;
  gap: 1rem;
}

.single-product .p-image-container {
  width: fit-content;
  height: 235px;
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
}

.single-product .p-image-container img {
  width: 270px;
  height: 100%;
  border-radius: 1rem;
}
