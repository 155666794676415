.btn-container {
  padding: 0.2rem 0;
}
.btn {
  font-size: 0.8rem;
  font-weight: 600;
  padding: 10px;
  border: none;
  border-radius: 1.5rem;
  color: var(--clr-font-white);
  /* background: var(--clr-btn-bg); */
  cursor: pointer !important;
  transition: 300ms ease-in;
}
.btn:hover {
  scale: 1.1;
}

@media (max-width: 768px) {
  .btn {
    font-size: 0.5rem;
  }
}
